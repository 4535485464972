import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/shared/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { About } from './components/About';
import { Products } from './components/Products';
import { Services } from './components/Services';
import { Contact } from './components/Contact';
//import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
            <Route path='/fetch-data' component={FetchData} />
            <Route path='/about' component={About} />
            <Route path='/products' component={Products} />
            <Route path='/services' component={Services} />
            <Route path='/contact' component={Contact} />
      </Layout>
    );
  }
}
