import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class About extends Component {
    static displayName = About.name;

   

    render() {
        return (
            <>

                {/*//Page Header Start */}
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div class="container text-center py-5">
                        <h1 class="display-2 text-dark mb-4 animated slideInDown">About Us</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol class="breadcrumb justify-content-center mb-0">
                                <li class="breadcrumb-item"> <Link to="/" >Home</Link></li>
               
                                <li class="breadcrumb-item text-dark" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* //Page Header End */}
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="row g-3">
                                    <div className="col-6 text-end">
                                        <img className="img-fluid bg-white w-100 mb-3 wow fadeIn" data-wow-delay="0.1s" src="assets/img/ro1.png" alt=""/>
                                        <img className="img-fluid bg-white w-50 wow fadeIn" data-wow-delay="0.2s" src="assets/img/ro2.png" alt=""/>
                        </div>
                                            <div className="col-6">
                                        <img className="img-fluid bg-white w-50 mb-3 wow fadeIn" data-wow-delay="0.3s" src="assets/img/purifier.png" alt=""/>
                                        <img className="img-fluid bg-white w-100 wow fadeIn" data-wow-delay="0.4s" src="assets/img/ropurifier.png" alt=""/>
                        </div>
                    </div>
                                            </div>
                                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                                <div className="section-title">
                                                    <p className="fs-5 fw-medium fst-italic text-primary">About Us</p>
                                                    <h1 className="display-6">The success history of MST Enterprises</h1>
                                                </div>
                                <div className="row g-3 mb-4">
                                    <p align ="justify">MST Enterprises offers a full range of water purification services in New Delhi, including 
                                        sales, services, repair, AMC, and installation of all kinds of water purifiers, softeners, and plants, for both domestic and commercial use. They have a wide selection of models, including Eureka Forbes Aquaaguard, Aqua Addition and AquaSure, as well as other brands like KENT and Aquafresh, as well as Nexus and Blue Star.
                                        They also have assembled water purifiers from Whirlpool and Havells.</p>
                                                </div>
                                                   
                                                    </div>
                                                </div>
                                            </div>
    </div>

          



            </>
        );
    }
}
