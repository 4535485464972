import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
        <>
            <div className="container-fluid px-0 mb-5">
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src="assets/img/ro1back.png" alt="Image"/>
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-7 text-center">
                                                <p className="fs-4 text-white animated zoomIn">Welcome to <strong className="text-dark">MST Enerprises</strong></p>
                                                <h1 className="display-1 text-dark mb-4 animated zoomIn">Whole Sale of RO Purifier</h1>
                                                <a href="" className="btn btn-light rounded-pill py-3 px-5 animated zoomIn">Explore More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                </div>
                            <div className="carousel-item">
                            <img className="w-100" src="assets/img/ro2back.png" alt="Image"/>
                                    <div className="carousel-caption">
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-7 text-center">
                                            <p className="fs-4 text-white animated zoomIn">Welcome to <strong className="text-dark">MST Enerprises</strong></p>
                                                    <h1 className="display-1 text-dark mb-4 animated zoomIn">RO Repair Services</h1>
                                                    <a href="" className="btn btn-light rounded-pill py-3 px-5 animated zoomIn">Explore More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </div>



                        <div className="carousel-item">
                            <img className="w-100" src="assets/img/ro3back.png" alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7 text-center">
                                            <p className="fs-4 text-white animated zoomIn">Welcome to <strong className="text-dark">MST Enerprises</strong></p>
                                            <h1 className="display-1 text-dark mb-4 animated zoomIn">RO Purifier Configuration</h1>
                                            <a href="" className="btn btn-light rounded-pill py-3 px-5 animated zoomIn">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                                data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                                data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-end">
                                    <img className="img-fluid bg-white w-100 mb-3 wow fadeIn" data-wow-delay="0.1s" src="assets/img/ro1.png" alt=""/>
                                    <img className="img-fluid bg-white w-50 wow fadeIn" data-wow-delay="0.2s" src="assets/img/ro3.png" alt=""/>
                        </div>
                                        <div className="col-6">
                                    <img className="img-fluid bg-white w-50 mb-3 wow fadeIn" data-wow-delay="0.3s" src="assets/img/ro2.png" alt=""/>
                                    <img className="img-fluid bg-white w-100 wow fadeIn" data-wow-delay="0.4s" src="assets/img/ro4.png" alt=""/>
                        </div>
                    </div>
                                        </div>
                                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                            <div className="section-title">
                                                <p className="fs-5 fw-medium fst-italic text-primary">About Us</p>
                                <h1 className="display-6">We offer a Whole Sale on RO water Purifiers.</h1>
                                            </div>
                                            <div className="row g-3 mb-4">
                                                
                                                    
                         </div>
                                                    <div className="col-sm-12">
                                                    <h5>Our business focuses on selling and delivering products through Whole Sale, Supply, and Services.
                                                     </h5>
                                                        </div>
                                              
                                                <div className="border-top mb-4"></div>
                                                <div className="row g-3">
                                                    <div className="col-sm-12">
                                    <h5>We offer solutions to issues related to RO water purifiers.</h5>
                                                       
                                                    </div>
                                                    <div className="col-sm-4">
                                    
                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            </div>



        </>
    );
  }
}
