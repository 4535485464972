import React, { Component } from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
//import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
        <>
           
    <div className="container-fluid bg-white sticky-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg bg-white navbar-light py-2 py-lg-0">
                        <a href="index.html" className="navbar-brand">
                            <img className="img-fluid" src="/assets/img/logoMST.png" alt="Logo">
                            </img>
                            </a>
                            <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarCollapse">
                                <div className="navbar-nav ms-auto">
                                <a href="index.html"></a>
                                <Link to="/" className="nav-item nav-link active">Home</Link>
                                <Link to="/about" className="nav-item nav-link">About</Link>
                                <Link to="/services" className="nav-item nav-link">Services</Link>

                                <div className="nav-item dropdown">
                                    <Link to="/products" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Products</Link>
                                    
                                    <div className="dropdown-menu bg-light rounded-0 m-0">
                                        <Link to="#" className="dropdown-item">RO Water Purifie</Link>

                                        <Link to="#" className="dropdown-item">Water FILTER</Link>

                                        <Link to="#" className="dropdown-item">Water Purifier Parts</Link>

                                        
                                       
                                    </div>
                                </div>
                                  
                                <Link to="#" className="nav-item nav-link">Store</Link>
                                <Link to="#" className="nav-item nav-link">Blogs</Link>
                                <Link to="/contact" className="nav-item nav-link">Contact</Link>
                                   
                                  
                                   
                                </div>
                                
                            </div>
            </nav>
        </div>
                </div>
              
        </>
    );
  }
}
