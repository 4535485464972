import React, { Component } from 'react';

export class Products extends Component {
    static displayName = Products.name;

   

    render() {
        return (
            <>
                <h1>Our Products</h1>
            </>
        );
    }
}
