import React, { Component } from 'react';

export class Contact extends Component {
    static displayName = Contact.name;

  

    render() {
        return (
            <>


                {/*   <!-- Contact Start -->*/}


                <div className="container-xxl contact py-5">
                    <div className="container">
                        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxwidth: "500px" }}>
                            <p className="fs-5 fw-medium fst-italic text-primary">Contact Us</p>
                            <h1 className="display-6">If You Have Any Query, Please Contact Us</h1>
                        </div>
                        <div className="row g-5 mb-5">
                            <div className="col-md-4 text-center wow fadeInUp" data-wow-delay="0.3s">
                                <div className="btn-square mx-auto mb-3">
                                    <i className="fa fa-envelope fa-2x text-white"></i>
                                </div>
                                <p className="mb-2">info@example.com</p>
                                <p className="mb-0">support@example.com</p>
                            </div>
                            <div className="col-md-4 text-center wow fadeInUp" data-wow-delay="0.4s">
                                <div className="btn-square mx-auto mb-3">
                                    <i className="fa fa-phone fa-2x text-white"></i>
                                </div>
                                <p className="mb-2">+012 345 67890</p>
                                <p className="mb-0">+012 345 67890</p>
                            </div>
                            <div className="col-md-4 text-center wow fadeInUp" data-wow-delay="0.5s">
                                <div className="btn-square mx-auto mb-3">
                                    <i className="fa fa-map-marker-alt fa-2x text-white"></i>
                                </div>
                                <p className="mb-2">Palam</p>
                                <p className="mb-0">New Delhi</p>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <h3 className="mb-4">Contact Form</h3>
                                 <form>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="name" placeholder="Your Name"/>
                                                    <label for="name">Your Name</label>
                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control" id="email" placeholder="Your Email"/>
                                                        <label for="email">Your Email</label>
                                </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control" id="subject" placeholder="Subject"/>
                                                            <label for="subject">Subject</label>
                                </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: "120px"}}></textarea>
                                                            <label for="message">Message</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Send Message</button>
                                                    </div>
                                                </div>
                    </form>
                                        </div>
                                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                            <div className="h-100">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14013.233518922938!2d77.0844315!3d28.590524149999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b3f9902dce7%3A0xdf51d27e693abbea!2sPalam%2C%20Delhi!5e0!3m2!1sen!2sin!4v1693417114215!5m2!1sen!2sin" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>
        </div>
                            </div>

                {/*   <!-- Contact End -->*/}



            </>
        );
    }
}
